import * as React from 'react';
import Row from 'react-bootstrap/Row';
import "../../App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Col from 'react-bootstrap/Col';
import VolunteersImage from '../../Images/Volunteer Images/VolunteersImage.png';
import YouthImage from '../../Images/Volunteer Images/YouthImage.png';
import PromotingDiversityAndInclusion from '../../Images/Volunteer Images/DiversityAndInclusion.png';
import CollectiveAction from '../../Images/Volunteer Images/CollectiveAction.png';



const OurGoals = () => {
    

    return (
        <>
            <Row>
                <Col style={{paddingBottom:"20px"}} md={6} sm={12} xs={12} lg={6}>
                <img style={{ borderRadius:"50px", justifyContent:"center"}} src={VolunteersImage} alt="Empowering Women"/>
                <h2>Empowering Women</h2>
                <p style={{paddingRight:"40px", paddingLeft:"40px"}}><b>We at Lamet Habayeb empower Women to achieve their untapped potential.</b></p>
                <p style={{paddingRight:"40px", paddingLeft:"40px"}}>We are a women-led community group with 80% of our committee members being women from Arabic countries - a grossly under representative group in British society and among the Arab community as a whole. All support given to us provides us with an opportunity to empower more women and to showcase our contribution to British society and our community as a whole. We want to break down the stereotypes that we see directed at women (in general) and Arab women every day.</p>
                </Col>
                <Col style={{paddingBottom:"20px"}} md={6} sm={12} xs={12} lg={6}>
                <img style={{ borderRadius:"50px", justifyContent:"center"}} src={YouthImage} alt="Lamet Habayeb Association Welcome stage message"/>
                <h2>Empowering the Youth</h2>
                <p style={{paddingRight:"40px", paddingLeft:"40px"}}><b>We at Lamet Habayeb understand youth are our future.</b></p>
                <p style={{paddingRight:"40px", paddingLeft:"40px"}}>Many of our volunteers are young children and adults. We are always looking for ways to provide them with leadership, organisational and skills for life. This is done through training and personal development.  Many of our events include the youth - we encourage them to take part in everything we do. At every point, we encourage them to contribute in the organisation of events alongside our adult volunteers. With every single donation and funding given to us, we create the future leaders of tomorrow.</p>
                </Col>
                <Col style={{paddingBottom:"10px"}} md={6} sm={12} xs={12} lg={6}>
                <img style={{ borderRadius:"50px", justifyContent:"center"}} src={PromotingDiversityAndInclusion} alt="Lamet Habayeb Association diversity"/>
                <h2>Promoting Diversity and Inclusion</h2>
                <p><b>We are a diverse community group!</b></p> 
                <p style={{paddingRight:"40px", paddingLeft:"40px"}}>We partner with a variety of different organisations including the Rochdale Multicultural Women Group to really boost the diversity and inclusion of people from all communities, ethnicities and nationalities. Everything we do is aimed at bringing all people together from the different parts of our community in a manner that allows us to celebrate our values, cultures and norms that we have adopted as part of the British society.</p>
                </Col>
                <Col style={{paddingBottom:"10px"}} md={6} sm={12} xs={12} lg={6}>
                <img style={{ borderRadius:"50px", justifyContent:"center"}} src={CollectiveAction} alt="Lamet Habayeb Association diversity"/>
                <h2>Collective Action</h2>
                <p style={{paddingRight:"40px", paddingLeft:"40px"}}><b>We understand the importance of bringing the community together. We work with all charities and organisations that reflect our values.</b></p> 
                <p style={{paddingRight:"40px", paddingLeft:"40px"}}>We always aim to bring our diverse community together as one to celebrate our culture and diversity with our wider community. The bonds that we create with our community members and other charities, allow us to create a more inclusive society that future generations will be proud of.</p>
                </Col>
            </Row>
        </>
    )
}

export default OurGoals;