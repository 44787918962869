import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import CallIcon from '@material-ui/icons/Call';
// import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { StyledEngineProvider } from '@mui/material/styles';
import ButtonLock from "../Components/ButtonLock";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Aos from "aos";
import "aos/dist/aos.css";
import NavigationBar from '../Components/NavigationBar';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';




const ContactUsSection = () => {
    React.useEffect(() => {
      Aos.init({ duration: 500 });
    }, []);
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const timer = React.useRef();
    const buttonSx = {
        ...(success && {
        bgcolor: green[500],
        '&:hover': {
        bgcolor: green[700],
      },
    }),
    };
    const handleButtonClick = () => {
        if (!loading) {
        setSuccess(false);
        setLoading(true);
        timer.current = window.setTimeout(() => {
            setSuccess(true);
            setLoading(false);
        }, 2000);
        }
    };
    return (
      <>
      <NavigationBar />
        <Row>
          <Col md={12} sm={12} lg={12} xs={12} xl={12}>
          <h1 data-aos="fade-up" style={{ color: "white", marginTop:"20px", marginBottom:"10px" }} className='ourvalues' >Contact Us</h1>
          </Col>
          <Col md={12}sm={12}lg={12}xs={12}xl={12} className="text-center">
            <p data-aos="flip-up" className="ourvalues">
              <strong>Note:</strong> All communication with us is confidential
              and any information that you entrust us with is securely stored.
            </p>
            <br/>
          </Col>
        </Row>
        <div style={{backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)", padding:"20px"}}>
        <Row >
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <Card style={{backgroundColor:"white", padding:"22px"}}  text="black">
              <Col className="text-center">
                <br />
                <HistoryEduIcon style={{ "color": "lightblue", fontSize:"67px", paddingBottom: "27px" }}/>
                <Card.Body>
                  <Card.Title>Message Us (Mobile Devices)</Card.Title>
                    <Card.Text><Row style={{justifyContent:"center"}}>Drop us a message and we will get back to you</Row>
                    <Row><b>Please note that any calls made will not be answered</b></Row></Card.Text>
                  <br />
                  <Col>
                    <Box sx={{ m: 1, position: "relative" }}>
                      <Button
                        style={{ backgroundColor: "green", color:"white" }}
                        variant="contained"
                        sx={buttonSx}
                        disabled={loading}
                        onClick={handleButtonClick}
                        href={"tel:+447404266559"}
                      >
                      Drop Us a Message
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: green[500],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Box>
                  </Col>
                </Card.Body>
              </Col>
            </Card>
          </Col>
          {/* <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <Card style={{backgroundColor:"white", padding:"20px"}}  text="black">
              <Col className="text-center">
                <br />
                <HistoryEduIcon style={{ "color": "lightblue", fontSize:"67px", paddingBottom: "27px" }}/>
                <Card.Body>
                  <Card.Title>Fill in a form</Card.Title>
                  <Card.Text>
                    Fill In this form and we will get back to you
                  </Card.Text>
                  <Col>
                      <Box sx={{ m: 1, position: "relative" }}>
                      <Button
                        style={{ backgroundColor: "green" }}
                        variant="contained"
                        sx={buttonSx}
                        disabled={loading}
                        onClick={handleButtonClick}
                        href="whatsapp://send?text=Hi, I would like some support&phone=+447404266559" 
                      >
                      Chat with us
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: green[500],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Box>
                  </Col>
                </Card.Body>
              </Col>
            </Card>
          </Col> */}
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <Card style={{backgroundColor:"white", padding:"22px"}}  text="black">
              <Col className="text-center">
                <br />
                <WhatsAppIcon style={{ "color": "lightblue", fontSize:"67px", paddingBottom: "27px" }}/>
                <Card.Body>
                  <Card.Title>WhatsApp</Card.Title>
                    <Card.Text><Row style={{justifyContent:"center"}}>Contact us via WhatsApp - a secure messaging app</Row>
                    <Row><b>Please note that any calls made will not be answered</b></Row></Card.Text>
                  <br />
                  <Col>
                    <Box sx={{ m: 1, position: "relative" }}>
                      <Button
                        style={{ backgroundColor: "green", color:"white" }}
                        variant="contained"
                        sx={buttonSx}
                        disabled={loading}
                        onClick={handleButtonClick}
                        href="whatsapp://send?text=Hi, my name is.... and I am contacting you because....&phone=+447404266559" 
                      >
                      Chat with us
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: green[500],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Box>
                  </Col>
                </Card.Body>
              </Col>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <Card style={{backgroundColor:"white", padding:"22px"}}  text="black">
              <Col className="text-center">
                <br />
                <EmailIcon style={{ "color": "lightblue", fontSize:"67px", paddingBottom: "27px" }}/>
                <Card.Body>
                  <Card.Title>Email</Card.Title>
                    <Card.Text><Row style={{justifyContent:"center"}}>Drop Us an Email - the simplest way to contact us</Row>
                    <Row><b>Please note that we try our best to get back to you ASAP</b></Row></Card.Text>
                  <br />
                  <Col>
                    <Box sx={{ m: 1, position: "relative" }}>
                      <Button
                        style={{ backgroundColor: "green", color:"white" }}
                        variant="contained"
                        sx={buttonSx}
                        disabled={loading}
                        onClick={handleButtonClick}
                        href="mailto:lamethabayeb@yahoo.com"
                      >
                        Email Us
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: green[500],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Box>
                  </Col>
                </Card.Body>
              </Col>
            </Card>
          </Col>
        </Row>
        </div>
    </>
    );
};
        

export default ContactUsSection;