import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import maleicon from "../../Images/mailusericon.png";
import femaleicon from "../../Images/femaleusericon.png";

export default class Testimonials extends Component {
  render() {
    return (
      <Carousel
        showArrows={false}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={4000}
      >
        <div>
          <img src={maleicon} />
          <div className="myCarousel">
            <h3>Refugee</h3>
            <h4>Lamet Habayeb wellbeing Projects</h4>
            <p>
                As a refugee, I have always felt alone and have been too scared to ask for
                support from anyone in the community as I spoke a different language and 
                came from a different culture. Through the wellbeing projects provided by 
                LHA, I have been able to find a safe place where I feel I can express myself 
                creatively. From therapy sessions through art to sewing and music sessions, I 
                now truly feel safe in a community that I have been introduced to.
            </p>
          </div>
        </div>

        <div>
          <img src={femaleicon} />
          <div className="myCarousel">
            <h3>Volunteer</h3>
            <h4>Lamet Habayeb Association Football Team</h4>
            <p>
                I have always thought about how hard it is to bring the older generations and 
                younger generations in our community together. However, the LHA football 
                team, as part of its training sessions and its games, has incorporated two 
                generations together. I have seen the youth take advice from the adults on 
                issues with regards to football, but also with regards to troubles that they face 
                in their day to day lives. From dealing with drug addiction, crimes and 
                antisocial behavior to supporting and keeping a close relationship with the 
                youth. I have never seen anything like this in all my years of volunteering. LHA 
                is doing amazing work!
            </p>
          </div>
        </div>

        <div>
          <img src={maleicon} />
          <div className="myCarousel">
            <h3>Youth</h3>
            <h4>Youth Club</h4>
            <p>
            I am given the chance to express myself, to be myself and most importantly
            to learn. I have seen myself increase in confidence and satisfaction as a result 
            of the many things I have contributed to whilst being part of the LHA youth 
            club. From volunteering and helping the adults to organise events to learning 
            about and mixing with the different communities, cultures and people that 
            make my community what it is. The welcoming atmosphere of LHA, especially 
            at the Youth Club makes me feel safe and part of a truly diverse community!
            </p>
          </div>
        </div>

        <div>
          <img src={femaleicon} />
          <div className="myCarousel">
            <h3>Member</h3>
            <h4>Walking Group</h4>
            <p>
              I never knew about the local and governmental efforts related to issues such 
              as the lack of community cohesion and domestic abuse. This walk allowed me 
              to learn so much! I will definitely spread my knowledge to my friends.
            </p>
          </div>
        </div>
      </Carousel>
    );
  }
}