import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Container from 'react-bootstrap/Container';
import "aos/dist/aos.css";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}



const BasicModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <>
    <Container data-aos="fade-up"
        data-aos-easing="ease-in-back"
        data-aos-delay="300"
        data-aos-offset="0" style={{paddingTop:"40px"}}>
    <ImageList
      style={{width:"100%", height:"100%"}}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
{/* Image 1 */}
        <ImageListItem key={props.images[1].img} cols={props.images[1].cols || 1} rows={props.images[1].rows || 1}>
          <img
            id='myImg'
            {...srcset(props.images[1].img, 121, props.images[1].rows, props.images[1].cols)}
            alt="title"
            loading="lazy"
            onClick={handleOpen}
          />

        </ImageListItem>
{/* Image 2 */}
        <ImageListItem key={props.images[2].img} cols={props.images[2].cols || 1} rows={props.images[2].rows || 1}>
          <img
            id='myImg'
            {...srcset(props.images[2].img, 121, props.images[2].rows, props.images[2].cols)}
            alt="title"
            loading="lazy"
            onClick={handleOpen}
          />

        </ImageListItem>
  {/* Image 3 */}
          <ImageListItem key={props.images[3].img} cols={props.images[3].cols || 1} rows={props.images[3].rows || 1}>
          <img
            id='myImg'
            {...srcset(props.images[3].img, 121, props.images[3].rows, props.images[3].cols)}
            alt="title"
            loading="lazy"
            onClick={handleOpen}
          />
        </ImageListItem>
{/* Image 4 */}
        <ImageListItem key={props.images[4].img} cols={props.images[4].cols || 1} rows={props.images[4].rows || 1}>
          <img
            id='myImg'
            {...srcset(props.images[4].img, 121, props.images[4].rows, props.images[4].cols)}
            alt="title"
            loading="lazy"
            onClick={handleOpen}
          />

        </ImageListItem>
{/* Image 5 */}
        <ImageListItem key={props.images[5].img} cols={props.images[5].cols || 1} rows={props.images[5].rows || 1}>
          <img
            id='myImg'
            {...srcset(props.images[5].img, 121, props.images[5].rows, props.images[5].cols)}
            alt="title"
            loading="lazy"
            onClick={handleOpen}
          />

        </ImageListItem>
{/* Image 6 */}
        <ImageListItem key={props.images[6].img} cols={props.images[6].cols || 1} rows={props.images[6].rows || 1}>
          <img
            id='myImg'
            {...srcset(props.images[6].img, 121, props.images[6].rows, props.images[6].cols)}
            alt="title"
            loading="lazy"
            onClick={handleOpen}
          />

        </ImageListItem>
{/* Image 7 */}
        <ImageListItem key={props.images[7].img} cols={props.images[7].cols || 1} rows={props.images[7].rows || 1}>
          <img
            id='myImg'
            {...srcset(props.images[7].img, 121, props.images[7].rows, props.images[7].cols)}
            alt="title"
            loading="lazy"
            onClick={handleOpen}
          />

        </ImageListItem>
{/* Image 8 */}
        <ImageListItem key={props.images[8].img} cols={props.images[8].cols || 1} rows={props.images[8].rows || 1}>
          <img
            id='myImg'
            {...srcset(props.images[8].img, 121, props.images[8].rows, props.images[8].cols)}
            alt="title"
            loading="lazy"
            onClick={handleOpen}
          />

        </ImageListItem>
{/* Image 9 */}
        <ImageListItem key={props.images[9].img} cols={props.images[9].cols || 1} rows={props.images[9].rows || 1}>
          <img
            id='myImg'
            {...srcset(props.images[9].img, 121, props.images[9].rows, props.images[9].cols)}
            alt="title"
            loading="lazy"
            onClick={handleOpen}
          />

        </ImageListItem>
{/* Image 10 */}
        <ImageListItem key={props.images[10].img} cols={props.images[10].cols || 1} rows={props.images[10].rows || 1}>
          <img
            id='myImg'
            {...srcset(props.images[10].img, 121, props.images[10].rows, props.images[10].cols)}
            alt="title"
            loading="lazy"
            onClick={handleOpen}
          />

          </ImageListItem>
{/* Image 11 */}
        <ImageListItem key={props.images[11].img} cols={props.images[11].cols || 1} rows={props.images[11].rows || 1}>
          <img
            id='myImg'
            {...srcset(props.images[11].img, 121, props.images[11].rows, props.images[11].cols)}
            alt="title"
            loading="lazy"
            onClick={handleOpen}
          />

        </ImageListItem>
    </ImageList>
    </Container>
    </>
  );

}
export default BasicModal;