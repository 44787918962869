import React from 'react';
import BasicModal from '../../Components/Modal';
import Aos from "aos";
import "aos/dist/aos.css";
import NavigationBar from '../../Components/NavigationBar';
import LHAFootballTeam1 from '../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam1.png';
import LHAFootballTeam2 from '../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam2.png';
import LHAFootballTeam3 from '../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam3.png';
import LHAFootballTeam4 from '../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam4.png';
import LHAFootballTeam5 from '../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam5.png';
import LHAFootballTeam6 from '../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam6.png';
import LHAFootballTeam7 from '../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam7.png';
import LHAFootballTeam8 from '../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam8.png';
import LHAFootballTeam9 from '../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam9.png';
import LHAFootballTeam10 from '../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam10.png';
import LHAFootballTeam11 from '../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam11.png';



import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}


const LHAFootballTeam = (props) => {
  React.useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
    return (
        <>
          <NavigationBar />
            <h1 id='back-to-top-anchor' className='ourvalues' data-aos="fade-up" style={{marginTop:"20px"}}> LHA Football Team </h1>
            <ScrollTop {...props}>
                <Fab style={{background:"gold"}} color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            <BasicModal images={itemData} />
        </>
    )
}

const itemData = [
  {
    img: `${LHAFootballTeam1}`,
    title: 'Lamet Habayeb Football Team',
    link: '',
    rows: 2,
    cols: 2,
    author: '@LametHabayeb',
  },
  {
    img: `${LHAFootballTeam1}`,
    title: 'Lamet Habayeb Football Team',
    link: '',
    author: '@LametHabayeb',
  },
  {
    img: `${LHAFootballTeam2}`,
    title: 'Lamet Habayeb Football Team',
    author: '@LametHabayeb',
  },
  {
    img: `${LHAFootballTeam3}`,
    title: 'Lamet Habayeb Football Team',
    Link: "",
    author: '@LametHabayeb',
    cols: 2,
  },
  {
    img: `${LHAFootballTeam4}`,
    title: 'Lamet Habayeb Football Team',
    Link: '',
    author: '@LametHabayeb',
    cols: 2,
  },
  {
    img: `${LHAFootballTeam6}`,
    Link: '',
    title: 'Lamet Habayeb Football Team',
    author: '@LametHabayeb',
    rows: 2,
    cols: 2,
  },
  {
    img: `${LHAFootballTeam5}`,
    title: 'Lamet Habayeb Football Team',
    Link: '',
    author: '@LametHabayeb',
  },
  {
    img: `${LHAFootballTeam7}`,
    title: 'Lamet Habayeb Football Team',
    author: '@LametHabayeb',
    Link: '',
  },
  {
    img: `${LHAFootballTeam8}`,
    title: 'Lamet Habayeb Football Team',
    Link: '',
    author: '@LametHabayeb',
    rows: 2,
    cols: 2,
  },
  {
    img: `${LHAFootballTeam9}`,
    title: 'Member Of LHA Football Team',
    Link: '',
    author: '@LametHabayeb',
  },
  {
    img: `${LHAFootballTeam10}`,
    title: 'Lamet Habayeb Football Team',
    Link: '',
    author: '@LametHabayeb',
  },
  {
    img: `${LHAFootballTeam11}`,
    title: 'Lamet Habayeb Football Team',
    Link: '',
    cols: 2,
    author: '@LametHabayeb',
  },
];


export default LHAFootballTeam;