import * as React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from '../../Components/NavigationBar';


const PayPalFail = () => {
  return (
    <>
      <NavigationBar/>
      <div>
      <Container className="text-center" style ={{padding:"20px"}}>   
        <div>
                      <Row style={{paddingBottom:"10px"}}><b>Your Donation makes a huge difference to what we can do as an organisation!</b></Row>
                      <Row style={{justifyContent:"center", paddingBottom:"10px"}}>You can check out the amazing work we do here at Lamet Habayeb Association by navigating via the Navigation Bar or by clicking on the following links: </Row>
                      <Row style={{paddingBottom:"10px"}}><b>Just some of the amazing projects we have to support the Arab community:</b></Row>
        </div>
                
                    <li>
                      <a href='/our-work/youth-club'>Youth Club</a>
                    </li>
                    <li>
                      <a href="/our-work/cooking-lessons">Cooking Lessons</a>
                    </li>
                    <li>
                      <a href="/our-work/wellbeing-projects">Wellbeing Projects</a>
                    </li>
                    <li>
                      <a href="/our-work/coffee-mornings">Coffee Mornings</a>
                    </li>
                    <li>
                      <a href="/our-work/lha-football-team">Lamet Habayeb Association Football Team - formally known as the <b>LHA Lions</b></a>
                    </li>
                    <li>
                      <a href="/our-work/trips">Scenic and Cultural Trips</a>
                    </li>
                    <li>
                      <a href="/our-work/walking-group">Walking Group</a>
                    </li>
                    <li>
                      <a href="/our-work/celebrations">Celebrations</a>
                    </li>
                  
        </Container>
        </div>
  </>
  );
}

export default PayPalFail;