import * as React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from '../../Components/NavigationBar';
import LHAFootballTeam from "../../Images/WhatWeDoPictures/LHAFootballTeam/LHAFootballTeam6.png";
import Carousel from "react-bootstrap/Carousel";
import Aos from 'aos';
import Button from '@mui/material/Button';
import { RWebShare } from 'react-web-share';
import { green } from '@mui/material/colors';


import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

const LHAFootballTeamUpdate = (props) => {
    React.useEffect(() => {
        Aos.init({ duration: 100 });
    }, []);
    const buttonSx = {
        ...({
        bgcolor: green[500],
            '&:hover': {
                bgcolor: "white",
            },
    }),
    };
  return (
      <>
      <NavigationBar />
        <h1 id="back-to-top-anchor" className="ourvalues" style={{marginTop:"10px", marginBottom:"30px"}}>The LHA Lions</h1>
        
        <ScrollTop {...props}>
            <Fab style={{background:"gold"}} color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
        
        <div style={{ paddingLeft: "25%", paddingRight: "25%" }}>
        <RWebShare  data={{text:"Lamet Habayeb Association", url:"https://lamethabayebassociation.org/recent-updates/LHA-Lions", title: "Lamet Habayeb Association - LHA Football Team"}}>
            <Button style={{ backgroundColor: "green", color:"white"}}>Click here to Share this event with your contacts!</Button>
        </RWebShare>
        </div>
        <div style={{backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)", padding:"20px"}}>
        <Container>
              <Row>
                  <Col md={12} sm={12} xs={12} lg={6} xl={6} data-aos="fade-up"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="800"
                      data-aos-offset="0">
                      <Carousel variant="dark" className="d-block w-100" >
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={LHAFootballTeam}
                                  alt="Second slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={LHAFootballTeam}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={LHAFootballTeam}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={LHAFootballTeam}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={LHAFootballTeam}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                      </Carousel>
                  </Col>
                  <Col md={12} sm={12} xs={12} lg={6} xl={6} data-aos="fade-up"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="400"
                      data-aos-offset="0">

                  <Row className='text-center' style={{ paddingTop:"2%", paddingBottom: "20px" }}><b>We would like to introduce the Lamet Habayeb Asscociation Football Team! </b></Row>
                  <Row style={{paddingBottom: "10px" }}>Our pride in our football team stems from its diversity and the competence of it members. It is made up of a set of young individuals with the power and determination to achieve whatever goals they are set. They prove this to us time and time again.</Row>

                  <Row style={{paddingBottom: "10px" }}>We created the LHA Lions to not only raise awareness of the Arab community in Rochdale, by giving the youth a platform to express themselves, but to also to reach out and showcase the outstanding talent that is present within the Arab community.</Row> 

                  <Row style={{paddingBottom: "10px" }}>As part of our strength to strength philosophy, our football team takes part in regular training sessions, but we don't just stop there. We also encourage the older members of our community to interact and educate our youth about the game - whatever their skills and knowledge. In essence, this allows our members and youth to benefit in the following ways:</Row>
                  <ul>
                    <li>Increased confidence and self belief</li>
                    <li>Better Mental Health accompanied by a positive mindset when faced with challenges</li>
                    <li>Increased Fitness - we take both the youth and adults into the outdoors</li>
                    <li>Increased community work and understanding - we encourage members to interact with the community</li>
                    <li>Life skills - one of the main ones being problem solving and team working/building exercises</li>      
                  </ul>

                  <Row style={{paddingBottom: "10px", paddingTop:"10px" }}>Want to check out some of the other events that we hold? Check out the variety of events, projects and activities were involved in:</Row>
                  <ul>
                    <li>
                      <a href='/our-work/youth-club'>Youth Club</a>
                    </li>
                    <li>
                      <a href="/our-work/cooking-lessons">Cooking Lessons</a>
                    </li>
                    <li>
                      <a href="/our-work/wellbeing-projects">Wellbeing Projects</a>
                    </li>
                    <li>
                      <a href="/our-work/coffee-mornings">Coffee Mornings</a>
                    </li>
                    <li>
                      <a href="/our-work/lha-football-team">Lamet Habayeb Association Football Team - formally known as the <b>LHA Lions</b></a>
                    </li>
                    <li>
                      <a href="/our-work/trips">Scenic and Cultural Trips</a>
                    </li>
                    <li>
                      <a href="/our-work/walking-group">Walking Group</a>
                    </li>
                    <li>
                      <a href="/our-work/celebrations">Celebrations</a>
                    </li>
                  </ul>
                  <Row><b>You can either click on the links above to learn more or navigate to other sections of the website through the navigation bar</b></Row>
                </Col>
              </Row>
          </Container>
        </div>
        </>
  );
}

export default LHAFootballTeamUpdate;