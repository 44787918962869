import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import "aos/dist/aos.css";

export default function EventsAndProjects(props) {
  return (
    <ImageList style={{width:"100%", height:"100%"}} key={1}>
      {props.images.map((item) => (
        <a href={item.link} className='backgroundcolor'>
        <ImageListItem key={item.img} data-aos="fade-up"
        data-aos-easing="ease-in-back"
        data-aos-delay="400"
        data-aos-offset="0">
              <img
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                  style={{height:"350px", margin:"10px"}}
              />
          <ImageListItemBar
            title={item.title}
            className="ourvalues, text-center"
            style={{background:"white", width:"100%", height:"100%",  color: "rgb(255,204,35)"}}
            class="text-center"
            position="below"
          />
        </ImageListItem>
        </a>

      ))}
    </ImageList>
  );
}

