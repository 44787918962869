import * as React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from '../../Components/NavigationBar';
import PlatinumJubilee from "../../Images/WhatWeDoPictures/Celebrations/JubileeInvitation.png";
import Carousel from "react-bootstrap/Carousel";
import Aos from 'aos';
import Button from '@mui/material/Button';
import { RWebShare } from 'react-web-share';
import { green } from '@mui/material/colors';


import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

const QueensPlatinumJubileeEvent = (props) => {
    React.useEffect(() => {
        Aos.init({ duration: 100 });
    }, []);
    const buttonSx = {
        ...({
        bgcolor: green[500],
            '&:hover': {
                bgcolor: "white",
            },
    }),
    };
  return (
      <>
      <NavigationBar />
        <h1 id="back-to-top-anchor" className="ourvalues" style={{marginTop:"10px", marginBottom:"30px"}}>The Queens Platinum Jubilee Celebration</h1>
        
        <ScrollTop {...props}>
            <Fab style={{background:"gold"}} color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
        
        <div style={{ paddingLeft: "25%", paddingRight: "25%" }}>
        <RWebShare  data={{text:"Lamet Habayeb Association", url:"https://lamethabayebassociation.org/recent-updates/Queens-Platinum-Jubilee-Event", title: "Lamet Habayeb Association - Platinum Jubilee Event"}}>
            <Button style={{ backgroundColor: "green", color:"white"}}>Click here to Share this event with your contacts!</Button>
        </RWebShare>
        </div>
        <div style={{backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)", padding:"20px"}}>
        <Container>
              <Row>
                  <Col md={12} sm={12} xs={12} lg={6} xl={6} data-aos="fade-up"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="800"
                      data-aos-offset="0">
                      <Carousel variant="dark" className="d-block w-100" >
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={PlatinumJubilee}
                                  alt="Second slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={PlatinumJubilee}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={PlatinumJubilee}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={PlatinumJubilee}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={PlatinumJubilee}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                      </Carousel>
                  </Col>
                  <Col md={12} sm={12} xs={12} lg={6} xl={6} data-aos="fade-up"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="400"
                      data-aos-offset="0">

                  <Row className='text-center' style={{ paddingTop:"2%", paddingBottom: "20px" }}><b>We would like to invite everyone to a celebration commemorating the #queensplatinumjubilee 🎉.</b></Row>
                  <Row style={{paddingBottom: "10px" }}>Join us on the 4th of June in commemorating this joyous occasion.</Row>

                  <Row style={{paddingBottom: "10px" }}>This forms part of our Eid preparations and we will have a variety of Arabian cuisine on offer.</Row> 

                  <Row style={{paddingBottom: "10px" }}>From amazing dishes to mouthwatering sweets, come and experience a plethora of Arabian cultures through their varying types and forms of food. There will also be:</Row>
<Row style={{paddingBottom: "10px" }}>Want to check out some of the other events that we hold? Check out the variety of events, projects and activities were involved in:</Row>
                  <ul>
                    <li>Music🎶</li>
                    <li>Arabic Singing🎤</li>
                    <li>Dancing</li>
                    <li>Children's Activities (sports and art based)</li>
                    <li>Picnics</li>      
                  </ul>

                  <Row style={{paddingBottom: "10px" }}>So why not join us for a day of family fun!</Row>
                  <b>Location: Broadfield Park, Rochdale</b>
                  
                  <Row style={{paddingBottom: "10px", paddingTop:"10px" }}>Want to check out some of the other events that we hold? Check out the variety of events, projects and activities were involved in:</Row>
                  <ul>
                    <li>
                      <a href='/our-work/youth-club'>Youth Club</a>
                    </li>
                    <li>
                      <a href="/our-work/cooking-lessons">Cooking Lessons</a>
                    </li>
                    <li>
                      <a href="/our-work/wellbeing-projects">Wellbeing Projects</a>
                    </li>
                    <li>
                      <a href="/our-work/coffee-mornings">Coffee Mornings</a>
                    </li>
                    <li>
                      <a href="/our-work/lha-football-team">Lamet Habayeb Association Football Team - formally known as the <b>LHA Lions</b></a>
                    </li>
                    <li>
                      <a href="/our-work/trips">Scenic and Cultural Trips</a>
                    </li>
                    <li>
                      <a href="/our-work/walking-group">Walking Group</a>
                    </li>
                    <li>
                      <a href="/our-work/celebrations">Celebrations</a>
                    </li>
                  </ul>
                  <Row><b>You can either click on the links above to learn more or navigate to other sections of the website through the navigation bar</b></Row>
                </Col>
              </Row>
          </Container>
        </div>
        </>
  );
}

export default QueensPlatinumJubileeEvent;