import * as React from 'react';
import Box from '@mui/material/Box';
import EventsAndProjects from '../Components/EventsAndProjects';
import Aos from "aos";
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Toolbar from '@mui/material/Toolbar';
import NavigationBar from '../Components/NavigationBar';
import youthImage from '../Images/HomePageArticleImages/SupportingOurCommunity.png';
import FoodPicture from '../Images/WhatWeDoPictures/FoodPictureMain.png';
import WellBeingProject from "../Images/WhatWeDoPictures/WellBeingProject.png";
import LHAFootballTeam from "../Images/WhatWeDoPictures/LHAFootballTeam.png";
import ScenicAndCulturalTrips from "../Images/WhatWeDoPictures/ScenicAndCulturalTrips.png";
import Scenic from "../Images/WhatWeDoPictures/Scenic.png";
import Celebrations from "../Images/WhatWeDoPictures/Celebrations.png"; 


// Trial
function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}
const WhatWeDo = (props) => {
  React.useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <>
    <NavigationBar />
    <Toolbar>
        <h1 className="ourvalues" data-aos="fade-up"
        data-aos-easing="ease-in-back"
        data-aos-delay="100"
        data-aos-offset="0" style={{marginTop:"20px"}}>What We Do</h1>
    </Toolbar>
    <Toolbar id="back-to-top-anchor"/>
        <EventsAndProjects images={itemData} />
        <ScrollTop {...props}>
          <Fab style={{background:"gold"}} color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
    </>
  );
}
const itemData = [
  {
    img:`${youthImage}`,
    title: 'Youth Club',
    author: '@lamethabayeb',
    link: '/our-work/youth-club',
  },
  {
    img: `${FoodPicture}`,
    title: 'Cooking Lessons',
    author: '@lamethabayeb',
    link: '/our-work/cooking-lessons',
  },
  {
    img: `${WellBeingProject}`,
    title: 'Wellbeing Projects',
    author: '@lamethabayeb',
    link: '/our-work/wellbeing-projects',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee Mornings',
    author: '@nolanissac',
    link: '/our-work/coffee-mornings',
  },
  {
    img: `${LHAFootballTeam}`,
    title: ' The LHA Football Team',
    author: '@lamethabayeb',
    link:'/our-work/lha-football-team',
  },
  {
    img: `${Scenic}`,
    title: 'Scenic and Cultural Trips',
    author: '@lamethabayeb',
    link: '/our-work/trips'
  },
  {
    img: `${ScenicAndCulturalTrips}`,
    title: 'Walking Group',
    author: '@lamethabayeb',
    link: '/our-work/walking-group',
  },
  {
    img: `${Celebrations}`,
    title: 'Celebrations',
    author: '@lamethabayeb',
    link: '/our-work/celebrations',
  },
  
];

export default WhatWeDo;