import React from 'react';
import BasicModal from '../../Components/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Aos from "aos";
import "aos/dist/aos.css";
import NavigationBar from '../../Components/NavigationBar';
import CookingImage1 from '../../Images/WhatWeDoPictures/Cooking Images/CookingImage1.png'
import CookingImage2 from '../../Images/WhatWeDoPictures/Cooking Images/CookingImage2.png'
import CookingImage3 from '../../Images/WhatWeDoPictures/Cooking Images/CookingImage3.png'
import CookingImage4 from '../../Images/WhatWeDoPictures/Cooking Images/CookingImage4.png'
import CookingImage5 from '../../Images/WhatWeDoPictures/Cooking Images/CookingImage5.png'
import CookingImage6 from '../../Images/WhatWeDoPictures/Cooking Images/CookingImage6.png'
import CookingImage7 from '../../Images/WhatWeDoPictures/Cooking Images/CookingImage7.png'
import CookingImage8 from '../../Images/WhatWeDoPictures/Cooking Images/CookingImage8.png'
import CookingImage9 from '../../Images/WhatWeDoPictures/Cooking Images/CookingImage9.png'
import CookingImage10 from '../../Images/WhatWeDoPictures/Cooking Images/CookingImage10.png'
import CookingImage11 from '../../Images/WhatWeDoPictures/Cooking Images/CookingImage11.png'


import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}


const CookingLessons = (props) => {
  React.useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
    return (
        <>
          <NavigationBar/>
            <h1 id="back-to-top-anchor" className='ourvalues' data-aos="fade-up" style={{marginTop:"20px", marginBottom:"30px"}}> Cooking Lessons </h1>
            <ScrollTop {...props}>
                <Fab style={{background:"gold"}} color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            <Container className='text-center' xl={6} lg={6} md={6} sm={6} xs={6} data-aos="fade-up"
        data-aos-easing="ease-in-back"
        data-aos-delay="400"
        data-aos-offset="0">
                <Row >
                    <Col>
                      <iframe src="https://www.youtube.com/embed/dTUVjyCv0p8" title="Shawarma Cooking Lesson with Basil and Mona from Lamet Habayeb Association" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                    <Col>
                      <iframe src="https://www.youtube.com/embed/gojsyVPQtC4" title="Hummus and Falafel Cooking Lesson with Basil and Mona from Lamet Habayeb Association" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                    <Col>
                      <iframe src="https://www.youtube.com/embed/CxG1RcxB5gE" title="Mabshura Cooking Lesson with Basil and Mona from Lamet Habayeb Association" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                    <Col>
                      <iframe src="https://www.youtube.com/embed/IygjcfuDTKM" title="Baqlawah Cooking Lesson with Basil and Mona from Lamet Habayeb Association" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                    <Col>
                      <iframe src="https://www.youtube.com/embed/KUNveUF25js" title="Kunafeh Nabulsiyi Cooking Lesson with Basil and Mona from Lamet Habayeb Association" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                    <Col>
                      <iframe src="https://www.youtube.com/embed/GCheyouJ-yI" title="Date Balls Cooking Lesson with Basil and Mona from Lamet Habayeb Association" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                </Row>
            </Container>
            <br />
            <br/>
            <BasicModal images={itemData} />
        </>
    )
}

const itemData = [
  {
    img: `${CookingImage1}`,
    title: 'Breakfast',
    link: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    rows: 2,
    cols: 2,
  },
  {
    img: `${CookingImage1}`,
    title: 'Dawali Arabian Food - Stuffed Vine Leaves (Vegan)',
    link: '',
  },
  {
    img: `${CookingImage2}`,
    title: 'Camera',
  },
  {
    img: `${CookingImage3}`,
    title: 'Coffee',
    Link: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    cols: 2,
  },
  {
    img:`${CookingImage4}`,
    title: 'Hats',
    Link: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    cols: 2,
  },
  {
    img: `${CookingImage5}`,
    Link: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img: `${CookingImage6}`,
    title: 'Kibbeh - Arabian Food. Prepared by members of Lamet Habayeb Association',
    Link: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
  },
  {
    img:`${CookingImage7}`,
    title: 'Fern',
    Link: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
  },
  {
    img:`${CookingImage8}`,
    title: 'Mushrooms',
    Link: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    rows: 2,
    cols: 2,
  },
  {
    img: `${CookingImage9}`,
    title: 'Tomato basil',
    Link: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
  },
  {
    img: `${CookingImage10}`,
    title: 'Sea star',
    Link: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
  },
  {
    img: `${CookingImage11}`,
    title: 'Bike',
    Link: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    cols: 2,
  },
];


export default CookingLessons;
// import React from 'react';
// import BasicModal from '../../Components/Modal';

// const CookingLessons = () => {
//     return (
//         <>
//             <h1 className='ourvalues'> Cooking Lessons </h1>
//             <br />
//             <br />
//             <iframe width="649" height="365" src="https://www.youtube.com/embed/oZi4IRDNWPs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
//             <br />
//             <br/>
//             <BasicModal images={itemData} />
//         </>
//     )
// }

// const itemData = [
//   {
//     img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
//     title: 'Breakfast',
//     link: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
//     rows: 2,
//     cols: 2,
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
//     title: 'Burger',
//     link: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
//     title: 'Camera',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
//     title: 'Coffee',
//     Link: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
//     cols: 2,
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
//     title: 'Hats',
//     Link: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
//     cols: 2,
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
//     Link: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
//     title: 'Honey',
//     author: '@arwinneil',
//     rows: 2,
//     cols: 2,
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
//     title: 'Basketball',
//     Link: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
//     title: 'Fern',
//     Link: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
//     title: 'Mushrooms',
//     Link: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
//     rows: 2,
//     cols: 2,
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
//     title: 'Tomato basil',
//     Link: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
//     title: 'Sea star',
//     Link: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
//     title: 'Bike',
//     Link: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
//     cols: 2,
//   },
// ];


// export default CookingLessons;