import * as React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from '../Components/NavigationBar';
import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';


function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

const Donations = (props) => {
    
  return (
    <>
      <NavigationBar />
      <div id='back-to-top-anchor'>
        <ScrollTop {...props}>
            <Fab style={{background:"gold"}} color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
      <Container className="text-center" style ={{padding:"20px"}}>   
        <div>
                      <Row style={{paddingBottom:"10px"}}><b>Thank you for supporting our group. Your contribution makes everything we do possible!</b></Row>
                      <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>You can donate to us via Bank Transfer <b>(we will receive your FULL donation):</b></Row>
                      <Row style={{ justifyContent: "center", paddingBottom: "10px" }}><b>Account Name:</b> LAMET HABAYEB ASSOCIATION</Row>
                      <Row style={{ justifyContent: "center", paddingBottom: "10px" }}><b>Sort Code:</b> 05-07-22</Row>
                      <Row style={{ justifyContent: "center", paddingBottom: "10px" }}><b>Account Number:</b> 24804798</Row>

                      {/* <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>You can donate to us via PayPal <b>(PayPal fees will be taken on your donation):</b></Row>
                      <Row style={{justifyContent:"center" }}><Col md={2} lg={2} sm={2} xl={2} xs={2} xll={2}>
                        <form action="https://www.paypal.com/donate" method="post" target="_top">
                        <input type="hidden" name="hosted_button_id" value="CPZGJGCJZJBSA" />
                        <input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                        <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                        </Col>
                      </Row> */}

                      <Row style={{paddingBottom:"10px"}}><b>If you would like to continue to hear about the great work we do, drop us an email with your <b>full name and affiliation</b> on <a href='mailto:lamethabayeb@yahoo.com'>lamethabayeb@yahoo.com</a> and we will add you to our contact list. You will also be among the first people invited to our events!</b></Row>
        </div>
        </Container>
        </div>
  </>
  );
}

export default Donations;