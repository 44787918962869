import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const ValuesTable = () => {
    
    function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
    }

    TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
 

    return (
        <Box
            className='carousel'
        >
            <Row >
                <Col md={4} sm={4} xs={4} lg={4} xl={4} >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Lamet Habayeb Association values"
                        sx={{ borderRight: 10, borderColor: 'divider' }}
                    >
                        <Tab label="Respect" {...a11yProps(1)} />
                        <Tab label="Diversity" {...a11yProps(0)} />
                        <Tab label="Inclusion" {...a11yProps(5)} />
                        <Tab label="Collective Action" {...a11yProps(2)} />
                        <Tab label="Dignity" {...a11yProps(3)} />
                        <Tab label="Excellence" {...a11yProps(4)} />
                    </Tabs>
                </Col>
                <Col md={8} sm={8} xs={8} lg={8} xl={8} >
                    <TabPanel value={value} index={0}>
                        In a world of constant change, respect for each other is what unites us. We understand this at Lamet Habayeb. We understand that it is our differences that give us our strength, creativity and the will to support others and the most vulnerable in our society.
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Row>Diversity is essential to us achieving our goals of benefitting and giving back to our community. Our organisation is made up of people from 20 different countries and nationalities. We all work together to make the amazing and life changing work we do a success.</Row>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        Inclusion is the corner stone of our non-profit organisation here at Lamet Habayeb. From supporting our members in integrating themselves into British society to providing the platform and safe conditions for them to express any concerns that hinder them from achieving their goals and aspirations. 
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Row>Collective Action is what were all about!</Row> <Row>We at Lamet Habayeb work with non-profit community groups, organisations and charities in achieving all of our goals. We believe that together we can TRULY GROW.</Row>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        We support, refugees, asylum seekers and the most vulnerable in society at Lamet Habayeb Association. People who have faced prosecution, discrimination and violence all demand one thing - dignity. Our organisation ensures that all the people we support are respected, treated fairly and have the right forms of support to live a peaceful and dignified life.
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        We strive for Excellence in everything we do here at Lamet Habayeb. That is why we ask our members and the people we support about how well we have served them and how we can do better. Life is a learning curve and, just like everything around us, were always evolving.
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        Item Seven
                    </TabPanel>
                </Col>
            </Row>
        </Box>
    )
};
export default ValuesTable;