import React from 'react';
import BasicModal from '../../Components/Modal';
import Aos from "aos";
import "aos/dist/aos.css";
import NavigationBar from '../../Components/NavigationBar';
import WalkingGroupImage1 from '../../Images/WhatWeDoPictures/WalkingGroup/WalkingGroupImage1.png';
import WalkingGroupImage2 from '../../Images/WhatWeDoPictures/WalkingGroup/WalkingGroupImage2.png';
import WalkingGroupImage3 from '../../Images/WhatWeDoPictures/WalkingGroup/WalkingGroupImage3.png';
import WalkingGroupImage4 from '../../Images/WhatWeDoPictures/WalkingGroup/WalkingGroupImage4.png';
import WalkingGroupImage5 from '../../Images/WhatWeDoPictures/WalkingGroup/WalkingGroupImage5.png';
import WalkingGroupImage6 from '../../Images/WhatWeDoPictures/WalkingGroup/WalkingGroupImage6.png';
import WalkingGroupImage7 from '../../Images/WhatWeDoPictures/WalkingGroup/WalkingGroupImage7.png';
import WalkingGroupImage8 from '../../Images/WhatWeDoPictures/WalkingGroup/WalkingGroupImage8.png';
import WalkingGroupImage9 from '../../Images/WhatWeDoPictures/WalkingGroup/WalkingGroupImage9.png';
import WalkingGroupImage10 from '../../Images/WhatWeDoPictures/WalkingGroup/WalkingGroupImage10.png';
import WalkingGroupImage11 from '../../Images/WhatWeDoPictures/WalkingGroup/WalkingGroupImage11.png';

import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}



const WalkingGroup = (props) => {
  React.useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
    return (
        <>
          <NavigationBar />
            <h1 id='back-to-top-anchor' className='ourvalues' data-aos="fade-up" style={{marginTop:"20px"}}> Walking Group </h1>
            <ScrollTop {...props}>
                <Fab style={{background:"gold"}} color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            <BasicModal images={itemData} />
        </>
    )
}

const itemData = [
  {
    img: `${WalkingGroupImage1}`,
    title: 'Walking Group Images by Lamet Habayeb Association',
    link: '',
    rows: 2,
    cols: 2,
  },
  {
    img: `${WalkingGroupImage1}`,
    title: 'Walking Group Images by Lamet Habayeb Association',
    link: '',
  },
  {
    img: `${WalkingGroupImage2}`,
    title: 'Walking Group Images by Lamet Habayeb Association',
  },
  {
    img: `${WalkingGroupImage3}`,
    title: 'Walking Group Images by Lamet Habayeb Association',
    Link: '',
    cols: 2,
  },
  {
    img: `${WalkingGroupImage4}`,
    title: 'Walking Group Images by Lamet Habayeb Association',
    Link: '',
    cols: 2,
  },
  {
    img: `${WalkingGroupImage5}`,
    Link: '',
    title: 'Walking Group Images by Lamet Habayeb Association',
    author: '@lamethabayeb',
    rows: 2,
    cols: 2,
  },
  {
    img: `${WalkingGroupImage6}`,
    title: 'Walking Group Images by Lamet Habayeb Association',
    Link: '',
  },
  {
    img: `${WalkingGroupImage7}`,
    title: 'Walking Group Images by Lamet Habayeb Association',
    Link: '',
  },
  {
    img: `${WalkingGroupImage8}`,
    title: 'Walking Group Images by Lamet Habayeb Association',
    Link: '',
    rows: 2,
    cols: 2,
  },
  {
    img: `${WalkingGroupImage9}`,
    title: 'Walking Group Images by Lamet Habayeb Association',
    Link: '',
  },
  {
    img: `${WalkingGroupImage10}`,
    title: 'Walking Group Images by Lamet Habayeb Association',
    Link: `${WalkingGroupImage10}`,
  },
  {
    img: `${WalkingGroupImage11}`,
    title: 'Walking Group Images by Lamet Habayeb Association',
    Link: `${WalkingGroupImage11}`,
    cols: 2,
  },
];


export default WalkingGroup;