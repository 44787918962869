import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import { UserInfoPage } from './pages/UserInfoPage';
// import { LogInPage } from './pages/LogInPage copy';
// import { SignUpPage } from './pages/SignUpPage';
// Private Route file/component imported
// import { PrivateRoute } from './auth/PrivateRoute';
import { HomePage } from './pages/Home';
import WhatWeDo from './pages/WhatWeDo';
import YouthClubPage from './pages/EventsAndProjects/YouthClub'
import CelebrationsPage from './pages/EventsAndProjects/CelebrationsPage';
import WalkingGroupPage from './pages/EventsAndProjects/WalkingGroupPage';
import TripsPage from './pages/EventsAndProjects/TripsPage';
import WellbeingProjects from './pages/EventsAndProjects/WellbeingProjectPage';
import CoffeeMornings from './pages/EventsAndProjects/CofeeMorningsPage';
import CookingLessons from './pages/EventsAndProjects/CookingLessonsPage';
import LHAFootballTeam from './pages/EventsAndProjects/LHAFootballTeamPage';
import Volunteers from './pages/Volunteers';
import ContactUsSection from './pages/ContactUsPage';
import ErrorPage from "./pages/ErrorPage";
import PageInMaking from './pages/ToBeUpdated';
import IntegratingArabCommunity from './pages/homePageArticles/IntegratingArabCommunity';
import SupportingTheCommunity from './pages/homePageArticles/SupportingTheCommunity';
import EveryoneWelcome from './pages/homePageArticles/EveryoneIsWelcome';
import PayPalFail from './pages/PaypalPages/PayPalDonationFail';
import PayPalSuccess from './pages/PaypalPages/PayPalDonationSuccess';
import Donations from './pages/DonationsPage';
import ArabianBazzar from './pages/UpdatesPages.js/ArabianBazaar';
import QueensPlatinumJubileeEvent from './pages/UpdatesPages.js/QueensPlatinumJubileeEvent';
import LHAFootballTeamUpdate from './pages/UpdatesPages.js/LHAFootballTeamUpdate';
import ContactForm from './pages/ContactForm';
import NetlifyContactForm from './Components/NetlifyContactForm';
import PrivacyPolicy from './pages/PrivacyPolicy';
// import { EmailVerificationLandingPage } from './pages/EmailVerificationLandingPage';
// import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
// import { PasswordResetLandingPage } from './pages/PasswordResetLandingPage';
// import { PleaseVerifyEmailPage } from './pages/PleaseVerifyEmailPage';
export const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/home" />
                </Route>
                <Route exact path={"/home"}>
                    <HomePage /> 
                </Route>
                <Route path={"/what-we-do"}>
                    <WhatWeDo />   
                </Route>
                <Route path={"/our-work/youth-club"}>
                    <YouthClubPage />
                </Route>
                <Route path={"/our-work/cooking-lessons"}>
                    <CookingLessons />
                </Route>
                <Route path={"/our-work/wellbeing-projects"}>
                    <WellbeingProjects />
                </Route>
                <Route path={"/our-work/coffee-mornings"}>
                    <CoffeeMornings />
                </Route>
                <Route path={"/our-work/lha-football-team"}>
                    <LHAFootballTeam />
                </Route>
                <Route path={"/our-work/trips"}>
                    <TripsPage />
                </Route>
                <Route path={"/our-work/walking-group"}>
                    <WalkingGroupPage />
                </Route>
                <Route path={"/our-work/celebrations"}>
                    <CelebrationsPage />
                </Route>
                <Route path={"/Volunteers"}>
                    <Volunteers />
                </Route>
                <Route path={"/contact-us"}>
                    <ContactUsSection />
                </Route>
                <Route path={"/pageInDevelopment"}>
                    <PageInMaking />
                </Route>
                <Route path={"/our-aims/integrating-the-arab-community"}>
                    <IntegratingArabCommunity />
                </Route>
                <Route path={"/our-aims/supporting-the-community"}>
                    <SupportingTheCommunity />
                </Route>
                <Route path={"/our-aims/everyone-is-welcome"}>
                    <EveryoneWelcome />
                </Route>
                <Route path={"/PayPal/Success"}>
                    <PayPalSuccess />
                </Route>
                <Route path={"/PayPal/Fail"}>
                    <PayPalFail />
                </Route>
                <Route path={"/Donations"}>
                    <Donations />
                </Route>
                <Route path={"/recent-updates/Arabian-Food-Bazaar"}>
                    <ArabianBazzar />
                </Route>
                <Route path={"/recent-updates/Queens-Platinum-Jubilee-Event"}>
                    <QueensPlatinumJubileeEvent />
                </Route>
                <Route path={"/recent-updates/LHA-Lions"}>
                    <LHAFootballTeamUpdate />
                </Route>
                <Route path={"/contact-form"}>
                  <NetlifyContactForm />
                </Route>
                <Route path={"/LHA-Privacy-Policy"}>
                  <PrivacyPolicy />
                </Route>
                <ErrorPage/>
            </Switch>
        </Router>
    );
}