import * as React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from '../Components/NavigationBar';

import Aos from 'aos';
import { RWebShare } from 'react-web-share';



import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}




const PrivacyPolicy = (props) => {
    React.useEffect(() => {
        Aos.init({ duration: 100 });
    }, []);

  return (
      <>
      <NavigationBar />
        <h1 id="back-to-top-anchor" className="ourvalues" style={{marginTop:"10px", marginBottom:"30px"}}>Privacy Policy - Lamet Habayeb Association</h1>
        
        <ScrollTop {...props}>
            <Fab style={{background:"gold"}} color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
        
        <div style={{backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)", padding:"20px"}}>
        <Container>
              <Row style={{justifyContent:"center"}}>
                  <Col md={12} sm={12} xs={12} lg={6} xl={6} data-aos="fade-up"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="400"
                      data-aos-offset="0">
                  <Row className='text-center' style={{ paddingTop:"2%", paddingBottom: "20px" }}><b>At Lamet Habayeb Association we take your personal data privacy seriously.</b></Row>
                  
                  <b><Row style={{paddingBottom: "10px" }} >Your information as a supporter of Lamet Habayeb Association:</Row></b>

                          
                  <Row style={{paddingBottom: "10px" }}>
                    We only hold the personal data that you have provided to us, with your consent, and as a necessary requirement for the effective functioning of Lamet Habayeb Association. This will comprise the following information that we are required by law to maintain in our register of members:
                    <li>
                        your name
                    </li>
                    <li>
                        your email address
                    </li>
                    <li>
                        your telephone number(s)
                    </li>
                    <li>
                        a list of those who attend events, activities and projects organised by Lamet Habayeb Association.
                    </li>
                  </Row>

                  <Row style={{paddingBottom: "10px" }}>We will not collect any additional personal data from you that we do not need.</Row> 

                  <Row style={{paddingBottom: "10px" }}>We will not share your information with any third parties (unless we are lawfully required to by a statutory regulator) or use your information for any purpose other than that required for the running of Lamet Habayeb Association.</Row>

                  <Row style={{paddingBottom: "10px" }}>Any personal information that you have provided to us is retained securely and only accessible to the management committee members that require it to discharge their legal responsibilities.</Row>

                  <Row style={{paddingBottom: "10px" }}>We will retain your personal information for the minimum period required by UK Law, after which your information will be securely destroyed.</Row>
                  
                  <b><Row style={{paddingBottom: "10px" }}>Your rights:</Row></b>
                  
                  <Row style={{paddingBottom: "10px" }}>If at any point you believe your information being held by Lamet Habayeb Association is incorrect or you wish to see the information, have it deleted or amended or have any queries, concerns or complaints about your data please do not hesitate to get in touch with Lamet Habayeb Associations data controller and the matter will be investigated.</Row>

                  <b><Row style={{paddingBottom: "10px" }}>Lamet Habayeb Association Data Controller: Omar Sharif</Row></b>

                  <b><Row href="mailto:lamethabayeb@yahoo.com" style={{paddingBottom: "10px" }} >lamethabayeb@yahoo.com </Row></b>

                  <Row style={{paddingBottom: "10px" }} href="https://goo.gl/maps/AjhkxrNKgsWXetkP8">Registered Office -  Better Together (Old Marks and Spencer) Building, 54 Yorkshire Street, Rochdale, OL16 1JW </Row>
          </Col>
              </Row>
          </Container>
        </div>
        </>
  );
}

export default PrivacyPolicy;