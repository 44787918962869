import React from 'react';
import BasicModal from '../../Components/Modal';
import Aos from "aos";
import "aos/dist/aos.css";
import NavigationBar from '../../Components/NavigationBar';
import youthClubImage1 from '../../Images/HomePageArticleImages/SupportingOurCommunity.png';
import YouthImage2 from '../../Images/WhatWeDoPictures/YouthImages/YouthImage2.png';
import YouthImage3 from '../../Images/WhatWeDoPictures/YouthImages/YouthImage3.png';
import YouthImage4 from '../../Images/WhatWeDoPictures/YouthImages/YouthImage4.png';
import YouthImage5 from '../../Images/WhatWeDoPictures/YouthImages/YouthImage5.png';
import YouthImage6 from '../../Images/WhatWeDoPictures/YouthImages/YouthImage6.png';
import YouthImage7 from '../../Images/WhatWeDoPictures/YouthImages/YouthImage7.png';
import YouthImage8 from '../../Images/WhatWeDoPictures/YouthImages/YouthImage8.png';
import YouthImage9 from '../../Images/WhatWeDoPictures/YouthImages/YouthImage9.png';
import YouthImage10 from '../../Images/WhatWeDoPictures/YouthImages/YouthImage10.png';
import YouthImage11 from '../../Images/WhatWeDoPictures/YouthImages/YouthImage11.png'


import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}



const YouthClubPage = (props) => {
  React.useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
    return (
        <>
          <NavigationBar />
            <h1 id="back-to-top-anchor" className='ourvalues' data-aos="fade-up" style={{marginTop:"20px"}}> LHA Youth Club </h1>
            <ScrollTop {...props}>
                <Fab style={{background:"gold"}} color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            <BasicModal images={itemData}/>
        </>
    )
}

const itemData = [
  {
    img: `${youthClubImage1}`,
    title: 'Youth activities during Lamet Habayeb event',
    link: '',
    rows: 2,
    cols: 2,
  },
  {
    img: `${youthClubImage1}`,
    title: 'Football activities during Lamet Habayeb picnic',
    author:"@lamethabayeb"
  },
  {
    img: `${YouthImage2}`,
    title: 'Football activities during Lamet Habayeb picnic',
    author:"@lamethabayeb"
  },
  {
    img: `${YouthImage3}`,
    title: 'Cinema at lamet habayeb office',
    Link: '',
    author:"@lamethabayeb",
    cols: 2,
  },
  {
    img: `${YouthImage4}`,
    title: 'Nature trips for youth by Lamet Habayeb',
    Link: '',
    author:"@lamethabayeb",
    cols: 2,
  },
  {
    img: `${YouthImage5}`,
    Link: '',
    title: 'Nature trips for youth by Lamet Habayeb',
    author:"@lamethabayeb",
    rows: 2,
    cols: 2,
  },
  {
    img: `${YouthImage6}`,
    title: 'Reservoir trips for youth by Lamet Habayeb',
    Link: '',
    author:"@lamethabayeb",
  },
  {
    img: `${YouthImage7}`,
    title: 'Culture trips for youth by lamet habayeb',
    Link: '',
    author:"@lamethabayeb",
  },
  {
    img: `${YouthImage8}`,
    title: 'Football equipment by Lamet Habayeb',
    Link: '',
    author:"@lamethabayeb",
    rows: 2,
    cols: 2,
  },
  {
    img: `${YouthImage9}`,
    title: 'Bunting and the Queens Jubilee youth decorations by Lamet Habayeb',
    Link: '',
    author:"@lamethabayeb",
  },
  {
    img: `${YouthImage10}`,
    title: 'Community Youth Engagement by Lamet Habayeb',
    Link: '',
    author:"@lamethabayeb",
  },
  {
    img: `${YouthImage11}`,
    title: 'Sports activities for the youth by Lamet Habayeb',
    Link: '',
    author:"@lamethabayeb",
    cols: 2,
  },
];


export default YouthClubPage;