import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ValuesTable from "./ValuesTable";
import RespectImage from "../../Images/RespectImage.png";
import DiversityImage from "../../Images/DiversityImage.png"
import InclusionImage from "../../Images/InclusionImage.png"
import BelongImage from "../../Images/BelongImage.png"
import ForAllImage from "../../Images/ForAllImage.png"
// import OffCanvasAlert from "./OffCanvasAlert";
import Aos from 'aos';

const CarouselTop = () => {
    React.useEffect(() => {
        Aos.init({ duration: 100 });
    }, []);
    return (
        <Container>
            <Row>
                <Col style={{paddingTop:"2.5%"}} md={12} sm={12} xs={12} lg={6} xl={6} data-aos="fade-up"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="400"
                    data-aos-offset="0">
                    
                    <ValuesTable />
                    
                </Col>
                <Col md={12} sm={12} xs={12} lg={6} xl={6} data-aos="fade-up"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="800"
                    data-aos-offset="0">
                    <Carousel variant="dark" className="d-block w-100" >
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={RespectImage}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={DiversityImage}
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={InclusionImage}
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={BelongImage}
                                alt="Fourth slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={ForAllImage}
                                alt="Fifth slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </Container>
    )
}

export default CarouselTop;