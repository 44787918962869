import * as React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from '../../Components/NavigationBar';
import EveryoneIsWelcome from "../../Images/HomePageArticleImages/EveryoneIsWelcome.png"
import Carousel from "react-bootstrap/Carousel";
import Aos from 'aos';


const EveryoneWelcome = () => {
    React.useEffect(() => {
        Aos.init({ duration: 100 });
    }, []);
  return (
      <>
      <NavigationBar />
        <h1 className="ourvalues" style={{marginTop:"10px", marginBottom:"30px"}}>Everyone Is Welcome!</h1>
        <div style={{backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)", padding:"20px"}}>
        <Container>
              <Row>
                  <Col md={12} sm={12} xs={12} lg={6} xl={6} data-aos="fade-up"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="800"
                      data-aos-offset="0">
                      <Carousel variant="dark" className="d-block w-100" >
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={EveryoneIsWelcome}
                                  alt="Second slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={EveryoneIsWelcome}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={EveryoneIsWelcome}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={EveryoneIsWelcome}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={EveryoneIsWelcome}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                      </Carousel>
                  </Col>
                  <Col md={12} sm={12} xs={12} lg={6} xl={6} data-aos="fade-up"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="400"
                      data-aos-offset="0">
                  <Row className='text-center' style={{ paddingTop:"2%", paddingBottom: "20px" }}><b>We are an inclusive organisation!</b></Row>
                  <Row style={{paddingBottom: "10px" }}>As an Arab group, our volunteers and the people we aim to support, come from different countries and continents in the world. The Arab Community that we support represents one of the most diverse groups of people on Earth. This means that we do not discriminate on ethnicity, race or gender. We promote equal opportunities for everyone.</Row>
                  <Row>As part of this, we hold/carry out a variety of events, projects and activities. These include:</Row>
                  <ul>
                    <li>
                      <a href='/our-work/youth-club'>Youth Club</a>
                    </li>
                    <li>
                      <a href="/our-work/cooking-lessons">Cooking Lessons</a>
                    </li>
                    <li>
                      <a href="/our-work/wellbeing-projects">Wellbeing Projects</a>
                    </li>
                    <li>
                      <a href="/our-work/coffee-mornings">Coffee Mornings</a>
                    </li>
                    <li>
                      <a href="/our-work/lha-football-team">Lamet Habayeb Association Football Team - formally known as the <b>LHA Lions</b></a>
                    </li>
                    <li>
                      <a href="/our-work/trips">Scenic and Cultural Trips</a>
                    </li>
                    <li>
                      <a href="/our-work/walking-group">Walking Group</a>
                    </li>
                    <li>
                      <a href="/our-work/celebrations">Celebrations</a>
                    </li>
                  </ul>
                  <Row><b>You can either click on the links above to learn more or navigate to other sections of the website through the navigation bar</b></Row>
          </Col>
              </Row>
          </Container>
        </div>
        </>
  );
}

export default EveryoneWelcome;