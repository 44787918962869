import * as React from 'react';
import NavigationBar from '../Components/NavigationBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import CarouselTop from '../Components/HomePage/CarouselBottom';
import "../App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Toolbar from '@mui/material/Toolbar';
import LametHabayebGroupImage from '../Images/LametHabayebGroupImage.png';
import Founders from './FoundersAndVolunteers/Founders';
import MovingText from 'react-moving-text'
import UpdatesComponent from '../Components/UpdatesComponent';
import ScrollTop from '../Components/HomePage/ScrollTop';
import WhoWeAre from '../Components/HomePage/WhoWeAre';
import OurGoals from '../Components/HomePage/OurGoals';
import Box from '@material-ui/core/Box';
import Col from "react-bootstrap/Col";
import Testimonials from '../Components/HomePage/Testimonials';


export const HomePage = (props) => {
  React.useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);



  function random(num) {
  return Math.floor(Math.random()*num)
}

function getRandomStyles() {
  var r = random(255);
  var g = random(255);
  var b = random(255);
  var mt = random(200);
  var ml = random(50);
  var dur = random(5)+5;
  return `
  background-color: rgba(${r},${g},${b},0.7);
  color: rgba(${r},${g},${b},0.7); 
  box-shadow: inset -7px -3px 10px rgba(${r-10},${g-10},${b-10},0.7);
  margin: ${mt}px 0 0 ${ml}px;
  animation: float ${dur}s ease-in infinite
  `
}

function createBalloons(num) {
  var balloonContainer = document.getElementById("balloon-container")
  for (var i = num; i > 0; i--) {
  var balloon = document.createElement("div");
  balloon.className = "balloon";
  balloon.style.cssText = getRandomStyles();           balloonContainer.append(balloon);
  }
}

window.onload = function() {
  createBalloons(100);
}

  return (
    <>
      <NavigationBar />
      <div style={{backgroundImage: "linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%)", color:"black", paddingTop:"10px", position:"absolute", height:"30%", width:"100%", id:"balloon-container", justifyContent:"center"}} data-aos="fade-up"
      data-aos-easing="ease-in-back"
      data-aos-delay="100"
      data-aos-offset="0" id="balloon-container">
        <div style={{position:"absolute"}} className="d-flex flex-row justify-content-center" >
          <h1 style={{position:"relative"}} className="p-2 ">We are here for the:</h1>
        <div style={{color:"red", position:"relative"}} className="headerSpan ">
        <b><MovingText type="typewriter"
          dataText={[
            'Arab Community',
            'Palestinian Community',
            'Syrian Community',
            'Libyan Community',
            'Algerian Community',
            'Morrocan Community',
            'Sudanese Community',
            'Egyptian Community',
            'Iraqi Community',
            'Lebanese Community',
            'Jordanian Community'
              ]} /></b>
        </div>
      </div>
      </div>
      <div className='text-center' style={{paddingBottom:"50px"}}data-aos="fade-up"
      data-aos-easing="ease-in-back"
      data-aos-delay="100"
      data-aos-offset="0">
        <img style={{ position: "relative", height: "40%", width: "60%", top: "90px" }} alt='Lamet Habayeb Association Group' src={LametHabayebGroupImage} />
        <Toolbar id="back-to-top-anchor"/>
      </div>
      {/* <div  style={{backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)", paddingTop:"20px", paddingBottom:"20px"}} className='col-sm-12 col-md-12'>
        <Toolbar>
          <CarouselTop />
        </Toolbar>
      </div> */}

      <Row className="row g-0">
      <Col md={12} lg={10} xl={10} sm={12} xs={12} xxl={10}>
      <Box>


      <div data-aos="fade-up"
      data-aos-easing="ease-in-back"
      data-aos-delay="100"
      data-aos-offset="0">
        <h1 className="ourvalues" style={{marginTop:"30px", marginBottom:"30px"}}>Who We Are:</h1>
      </div>

      <div className='text-center' style={{marginTop:"30px", padding:"20px",  backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)"}} data-aos="fade-up"
        data-aos-easing="ease-in-back"
        data-aos-delay="300"
        data-aos-offset="0">
        <WhoWeAre />
      </div>

      <div data-aos="fade-up"
      data-aos-easing="ease-in-back"
      data-aos-delay="100"
      data-aos-offset="0">
        <h1 className="ourvalues" style={{marginTop:"30px", marginBottom:"30px"}}>Our Goals:</h1>
      </div>
      
      <div className='text-center' style={{ marginTop: "30px", padding: "20px", backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)" }} data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0">
      <OurGoals />
      </div>

      <div data-aos="fade-up"
      data-aos-easing="ease-in-back"
      data-aos-delay="100"
      data-aos-offset="0">
        <h1 className="ourvalues" style={{marginTop:"30px", marginBottom:"30px"}}>Our Values</h1>
      </div>
      <div style={{backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)", paddingTop:"10px", paddingBottom:"20px"}}>
        <Toolbar >
          <CarouselTop /> 
        </Toolbar>
      </div>
      <h1 data-aos="fade-up" style={{ color: "white", marginTop:"40px", marginBottom:"40px" }} className='ourvalues'>Our Founders</h1>
      <div style={{backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)"}} >
      <Row data-aos="fade-up">
          <Founders/>
      </Row>
      </div>
      <div data-aos="fade-up"
      data-aos-easing="ease-in-back"
      data-aos-delay="100"
      data-aos-offset="0">
        <h1 className="ourvalues" style={{marginTop:"20px", marginBottom:"10px"}} id='recent-updates'> Testimonials:</h1>
      </div>
      <div className='text-center' style={{ marginTop: "30px", padding: "20px", backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)" }} data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0">
      <Testimonials/>
      </div>
      </Box>
      </Col>
      
        
      <Col md={12} lg={2} xl={2} xxl={2} sm={12} xs={12} >
      <Box>
      <div data-aos="fade-up"
      data-aos-easing="ease-in-back"
      data-aos-delay="100"
      data-aos-offset="0">
        <h1 className="updatesHeading" style={{marginTop:"20px", marginBottom:"10px"}} id='recent-updates'> Updates:</h1>
      </div>
{/* Used the 'list' property in the updatescomponent and the ScrollTop Component (this is in the components folder) component from material UI to make it scrollable */}
            <UpdatesComponent  />
      </Box>
      </Col>
      </Row>
      <ScrollTop /> 

    </>
  );
}


