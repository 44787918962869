import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Logo from "./Logo";
import FacebookIcon from '@mui/icons-material/FacebookSharp';
import Twitter from '@mui/icons-material/Twitter';
import Email from '@mui/icons-material/EmailSharp';

function Footer() {
    return (
        <footer className="main-footer" style={{backgroundColor:"white"}} >
            <Container fluid className="text-center">
                <Row>
                    <Col md={4} sm={8}>
                        <Email style={{ "color": "lightblue", fontSize:"67px", paddingBottom: "27px" }}/>
                            <ul className="list-unstyled" style={{ 'fontSize': '17px', "paddingTop": "10px", "paddingBottom": "27px" }}>
                                <li><Logo/></li>
                                <li style={{color:"black"}}>Better Together Building</li>
                                <li style={{color:"black"}}>54 Yorkshire Street, Rochdale</li>
                                <li style={{ color: "black" }}>OL16 1JW, United Kingdom</li>
                                <br/>
                                <li><strong style={{color:"black"}}>Phone:</strong> <a href="tel:+447404266559" style={{color:"blue",fontWeight:"80px", textDecoration:"none"}}>+447404266559</a></li>
                                <li><strong style={{color:"black"}}>Email:</strong> <a href="mailto:lamethabayeb@yahoo.com" style={{fontSize:"5", color: "blue", textDecoration:"none", fontStyle: "italic" }}>lamethabayeb@yahoo.com</a></li>
                                <li><strong style={{color:"black"}}>Donations:</strong> <a href="https://www.paypal.com/donate/?hosted_button_id=CPZGJGCJZJBSA" style={{ color: "blue", textDecoration:"none", fontStyle: "italic" }}>PayPal Account</a></li>
                            </ul>
                    </Col>
                    <Col md={4} sm={8} >
                        <Row style={{justifyContent:"center"}}>
                        <FacebookIcon style={{ "color": "lightblue", fontSize: "67px", paddingBottom: "27px" }} />
                        </Row>
                        <Row style={{justifyContent:"center"}}>
                        <iframe title=" Lamet Habayeb Association Facebook page" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flamethabayebofficial%2F&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" height="300" scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </Row>
                    </Col>
                    <Col md={4} sm={8}>
                        <Twitter style={{ "color": "lightblue", fontSize:"67px", paddingBottom: "27px" }} />
                        <br/>
                        <Row>
                        <a className="twitter-timeline" data-width="405" data-height="214" data-theme="dark" href="https://twitter.com/LametHabayeb?ref_src=twsrc%5Etfw">Tweets by LametHabayeb</a> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                        </Row>
                        <Row>
                        <a href="https://twitter.com/LametHabayeb?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-size="large" data-show-count="false">Follow @LametHabayeb</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                        </Row>
                    </Col>
                </Row>
                <Row style={{justifyContent:"center", padding:"10px"}}><b>©2022 Lamet Habayeb Association. All rights reserved</b></Row>
        </Container>
        </footer>
        
    )
}

export default Footer;