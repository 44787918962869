import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import "aos/dist/aos.css";
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import useScrollTrigger from '@mui/material/useScrollTrigger';


    
function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <>
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 17, left: 0 }}
      >
          {children}

      </Box>
      
    </Zoom>

    </>
  );
}

    
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

    
const ScrollTopAction = (props) => {
    return (
        <>
      <ScrollTop {...props}>
        <div className="icon-bar" >
        <a href="https://www.facebook.com/lamethabayebofficial/" className="facebook"><i className="fa fa-facebook"></i></a> 
        <a href="https://twitter.com/lamethabayeb?lang=en" className="twitter"><i className="fa fa-twitter"></i></a> 
        <a href="https://lamethabayebassociation.org/home" className="google"><i className="fa fa-google"></i></a> 
        <a href="https://www.linkedin.com/company/lamet-habayeb" className="linkedin"><i className="fa fa-linkedin"></i></a>
        <a href="https://www.youtube.com/channel/UCw1Wu2VJa7M16w-r_YrjhXA" className="youtube"><i className="fa fa-youtube"></i></a> 
        </div>
      </ScrollTop>
      <ScrollTop {...props}>
        <Fab style={{ background: "gold" }} color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      </>
    )
}
export default ScrollTopAction;