import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RWebShare } from 'react-web-share';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

export default function UpdatesComponent() {
    return (
        <>
            <Container className="text-center" style={{ padding: "20px" }}>
                <Row style={{ flexDirection: "row", justifyContent:"center"  }} >
                    <List sx={{
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 3550,
                            }}>
                        {updates.map((item) => (
                            <Col key={item.title} style={{marginBottom:"10px"}}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        alt={item.alt}
                                        height="140"
                                        image={item.img}  
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {item.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <RWebShare data={{text:"Lamet Habayeb Association", url:"https://lamethabayebassociation.org/home/#recent-updates", title: "Lamet Habayeb Association - recent updates"}}>
                                            <Button size="large">Share</Button>
                                        </RWebShare>
                                        <Button size="large" href={item.href}>Learn More</Button>
                                    </CardActions>
                                </Card>
                            </Col>
                            ))}
                    </List>        
                </Row>
            </Container>
        </>
    )
};

const updates = [
    {
        img: "https://scontent.flhr6-1.fna.fbcdn.net/v/t39.30808-6/292076635_758374428945816_4220602921563523678_n.jpg?stp=dst-jpg_p843x403&_nc_cat=105&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=cmahwGB0joUAX8_iw4y&_nc_ht=scontent.flhr6-1.fna&oh=00_AT9zpzsR1I5u4B01ym73ne8rlA0DM77q-5ENUW-4_p6sHQ&oe=62C9011E",
        alt:"UPDATE - Arabian Food Bazaar",
        title: "UPDATE - The first ever Arabian Food Bazaar in Rochdale",
        description: "Join us on the 8th of July from 11am to 4pm and come and experience the best of Arab culture! There will be new variants of food on offer!",
        outline: "",
        href:"/recent-updates/Arabian-Food-Bazaar",
    },
    {
        img: "https://pbs.twimg.com/media/FWHMMluVsAIvRlX?format=jpg",
        alt:"/recent-updates/Arabian-Food-Bazaar",
        title: "The first ever Arabian Food Bazaar in Rochdale",
        description: "Join us on the 1st and 2nd of July from 11am to 4pm and come and experience the best of Arab culture!",
        outline: "",
        href:"/recent-updates/Arabian-Food-Bazaar",
    },
    {
        img: "https://pbs.twimg.com/media/FUUwXzWXwAElfHk?format=jpg&name=medium",
        alt:"Queens Platinum Jubilee Event",
        title: "Queens Platinum Jubilee Event",
        description: "Join us on the 4th of June from 2pm to 5pm in celebrating the Queens Platinum Jubilee at Broadfield Park Rochdale. ",
        outline: "",  
        href:"/recent-updates/Queens-Platinum-Jubilee-Event",
    },
    {
        img: "https://pbs.twimg.com/media/FT_wdOcXsAIMc2g?format=jpg&name=medium",
        alt:"lha lions football team",
        title: "LHA lions football team",
        description:"Check out our football team upon completing the #football tournament at the Crown Oil Arena! We've only had a couple of training sessions, but they did us proud👏! They are truly destined for great things💪.",
        outline: "",
        href:"/recent-updates/LHA-Lions",
    },
    {
        img: "https://pbs.twimg.com/media/FTWwPJYXwAQzWV2?format=jpg&name=large",
        alt:"Football Training",
        title: "Football Training for our youth football team",
        description: "A fun day of training for our youth as they prepare to take part in the inspiring communities youth football tournament ⚽🏆.It was great to see them represent our group and publicise the Queens #JubileeCelebration event were holding 👑.",
        outline: "",
        href:"/recent-updates/LHA-Lions",
    },
    {
        img: "https://pbs.twimg.com/media/FR2vzN6XEAYetn7?format=jpg&name=large",
        alt:"Queens Jubilee Lamet Habayeb Association office decoration ",
        title: "Decorating our office in preparation for the Queens Jubilee",
        description: "All our volunteers have been extremely busy recently as we gear up for the big Jubilee celebration. We have set up our cenematic experience gazebo and decorated our base to reflect all of this!",
        outline: "",
        href:"/recent-updates/Queens-Platinum-Jubilee-Event",
    },
    {
        img: "https://pbs.twimg.com/media/FVoGu0cWQAA7Vay?format=jpg&name=large",
        alt:"Queens Jubilee London Trip ",
        title: "Queens Jubilee London Trip",
        description: "An #amazing day yesterday as we spent the final part of our #Jubileecelebration outside Buckingham Palace whilst also visiting some of the key historical monuments in London. All our volunteers have been extremely busy recently in coordinating this trip. It has now become a reality. People really enjoyed the visit and got to see the true beauty of London and its history!",
        outline: "",
        href:"/recent-updates/Queens-Platinum-Jubilee-Event",
    },
        

]
