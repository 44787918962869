import { NetlifyForm, Honeypot, Recaptcha } from 'react-netlify-forms'
// const SITE_RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;


// const SITE_RECAPTCHA_KEY = "6LfkqwkhAAAAAOc0J2cDdhyErrIOVWJEzmVnZU-J"

export default function NetlifyContactForm(prop) {
    return (
        <>
            <NetlifyForm
                name='reCAPTCHA'
                action='/home'
                honeypotName='bot-field'
                enableRecaptcha
                onSuccess={(response, context) => {
                    console.log('Successfully sent form data to Netlify Server')
                    context.formRef.current.reset()
                }}
            >
                {({ handleChange, success, error }) => (
                    <>
                        <Honeypot />
                        <Recaptcha siteKey={process.env.SITE_RECAPTCHA_KEY} invisible />
                        {success && (
                            <p sx={{ variant: 'alerts.success', p: 3 }}>
                                Thanks for contacting us!
                            </p>
                        )}
                        {error && (
                            <p sx={{ variant: 'alerts.muted', p: 3 }}>
                                Sorry, we could not reach servers. Because it only works on Netlify,
                                our GitHub demo does not provide a response.
                            </p>
                        )}
                        <div>
                            <label htmlFor='name' sx={{ variant: 'forms.label' }}>
                                Name:
                            </label>
                            <input
                                type='text'
                                name='name'
                                id='name'
                                onChange={handleChange}
                                sx={{ variant: 'forms.input' }}
                            />
                        </div>
                        <div sx={{ pt: 2 }}>
                            <label htmlFor='message' sx={{ variant: 'forms.label' }}>
                                Message:
                            </label>
                            <textarea
                                type='text'
                                name='message'
                                id='message'
                                rows='4'
                                onChange={handleChange}
                                sx={{ variant: 'forms.textarea' }}
                            />
                        </div>
                        <div sx={{ pt: 3 }}>
                            <button type='submit' sx={{ variant: 'buttons.success' }}>
                                Submit
                            </button>
                            <button type='reset' sx={{ variant: 'buttons.danger' }}>
                                Reset
                            </button>
                        </div>
                    </>
                )}
            </NetlifyForm>
        </>
    )
}