import React from 'react';
import BasicModal from '../../Components/Modal';
import Aos from "aos";
import "aos/dist/aos.css";
import NavigationBar from '../../Components/NavigationBar';
import CelebrationImages1 from '../../Images/WhatWeDoPictures/Celebrations/Celebrations1.png';
import CelebrationImages2 from '../../Images/WhatWeDoPictures/Celebrations/Celebrations2.png';
import CelebrationImages3 from '../../Images/WhatWeDoPictures/Celebrations/Celebrations3.png';
import CelebrationImages4 from '../../Images/WhatWeDoPictures/Celebrations/Celebrations4.png';
import CelebrationImages5 from '../../Images/WhatWeDoPictures/Celebrations/Celebrations5.png';
import CelebrationImages6 from '../../Images/WhatWeDoPictures/Celebrations/Celebrations6.png';
import CelebrationImages7 from '../../Images/WhatWeDoPictures/Celebrations/Celebrations7.png';
import CelebrationImages8 from '../../Images/WhatWeDoPictures/Celebrations/Celebrations8.png';
import CelebrationImages9 from '../../Images/WhatWeDoPictures/Celebrations/Celebrations9.png';
import CelebrationImages10 from '../../Images/WhatWeDoPictures/Celebrations/Celebrations10.png';
import CelebrationImages11 from '../../Images/WhatWeDoPictures/Celebrations/Celebrations11.png';

import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';


function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}


const CelebrationsPage = (props) => {
  React.useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
    return (
        <>
          <NavigationBar />
          <h1 id='back-to-top-anchor' className='ourvalues' data-aos="fade-up" style={{ marginTop: "20px" }}> Celebrations </h1>
          <ScrollTop {...props}>
              <Fab style={{background:"gold"}} color="primary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
              </Fab>
          </ScrollTop>
            <BasicModal images={itemData}/>
        </>
    )
}

const itemData = [
  {
    img: `${CelebrationImages1}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    link: `${CelebrationImages1}`,
    author: '@Lamet Habayeb',
    rows: 2,
    cols: 2,
  },
  {
    img: `${CelebrationImages1}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    link: `${CelebrationImages1}`,
    author: '@Lamet Habayeb',
  },
  {
    img: `${CelebrationImages2}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    author: '@Lamet Habayeb',
  },
  {
    img: `${CelebrationImages3}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    Link: `${CelebrationImages3}`,
    author: '@Lamet Habayeb',
    cols: 2,
  },
  {
    img: `${CelebrationImages4}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    Link: `${CelebrationImages4}`,
    author: '@Lamet Habayeb',
    cols: 2,
  },
  {
    img: `${CelebrationImages5}`,
    Link: `${CelebrationImages5}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    author: '@Lamet Habayeb',
    rows: 2,
    cols: 2,
  },
  {
    img: `${CelebrationImages6}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    Link: `${CelebrationImages6}`,
    author: '@Lamet Habayeb',
  },
  {
    img: `${CelebrationImages7}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    Link: `${CelebrationImages7}`,
    author: '@Lamet Habayeb',
  },
  {
    img: `${CelebrationImages8}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    Link: `${CelebrationImages8}`,
    author: '@Lamet Habayeb',
    rows: 2,
    cols: 2,
  },
  {
    img: `${CelebrationImages9}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    Link: `${CelebrationImages9}`,
    author: '@Lamet Habayeb',
  },
  {
    img: `${CelebrationImages10}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    Link: `${CelebrationImages10}`,
    author: '@Lamet Habayeb',
  },
  {
    img: `${CelebrationImages11}`,
    title: 'Celebration Images from Lamet Habayeb Association Events',
    Link: `${CelebrationImages11}`,
    author: '@Lamet Habayeb',
    cols: 2,
  },
];


export default CelebrationsPage;