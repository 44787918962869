import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes } from './Routes';
import Container from 'react-bootstrap/Container';
import Footer from './Components/Footer';

export const App = () => {
    return (
        
        <div className="page-container">
            <Container fluid style={{ margin: "0px", padding: "0px", backgroundColor:"white", color:"black", overflowX: "hidden" }}>
                <br/>
                <Routes />
                <br />
                <hr />
                <div>
                    <Footer /> 
                </div>    
            </Container>
        </div>
    );
}
