import * as React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from '../Components/NavigationBar';


const ErrorPage = () => {
  return (
    <>
      <NavigationBar/>
      <div>
      <Container className="text-center" style ={{padding:"20px"}}>   
        <div>
                      <Row><b>404 ERROR</b></Row>
                      <Row>Sorry about this, but it seems that you have tried to access a page that does not exist. We are always updating our website with the amazing things and activities we do to support our community</Row>
                      <Row><b>You can navigate to other sections of the website through the navigation bar</b></Row>
        </div>
        </Container>
        </div>
  </>
  );
}

export default ErrorPage;