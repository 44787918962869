import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from "react-bootstrap/Container";
import Souhad from "../../VolunteerImages/Souhad.png";
import Wafa from "../../VolunteerImages/WafaImage.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ViceChair from "../../Images/ViceChair.png";
import Aos from 'aos';




const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Volunteers = () => {
  React.useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  const [expanded, setExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [expanded3, setExpanded3] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };
  const handleExpandClick3 = () => {
    setExpanded3(!expanded3);
  };

      const FoundersData = [
  {
    Name: "Dr Souhad Hijazi",
    Info: "Dr Souhad Hijazi is the founder of Lamet Habayeb Association. She is the bed-rock of our organisation and a great motivation behind all the amazing work we do. Souhad is a truly amazing person with an outstanding personality.",
    Img: `${Souhad}`,
    Title:"Founder",
    expanded: expanded,
    handleExpandClick: handleExpandClick,
    
  },
  {
    Name: "Wafa Takheroubt",
    Info: "Wafa Takheroubt is the co-founder of Lamet Habayeb association. Her self-less attitude, perseverance and determination is supporting all those around her is something to be admired. She is true asset to our organisation. ",
    Img: `${Wafa}`,
    Title: "Co-Founder and Chair",
    expanded: expanded2,
    handleExpandClick: handleExpandClick2 ,

  },
  {
    Name: "Omar Sharif",
    Info: "A student and IT professional, his experience and dedication to supporting those around him place him as one of our longest serving volunteers and the voluntary Vice Chair of our organisation.  ",
    Img: `${ViceChair}`,
    Title: "Co-Founder and Vice Chair",
    expanded: expanded3,
    handleExpandClick: handleExpandClick3,

  }
]

  return (
    <>
      <div>
      <Container className="text-center" style ={{padding:"20px"}}>   
        <Row>
          {FoundersData.map((item) => (
            <Col xs={12} sm={12} md={12} lg={4} xl={4} style={{ padding: "10px" }} key={item.Name} >
              <Card style ={{backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)", color:"black"}} data-aos="fade-up"
                data-aos-easing="ease-in-back"
                data-aos-delay="400"
                data-aos-offset="0">
                  <CardHeader
                      title={item.Name}
                  subheader={<strong style={{ color: "black" }}>{item.Title}</strong>}
                      />
                        <CardMedia
                          component="img"
                          height="20%"
                          width="20%"
                          image={item.Img}
                          alt="Paella dish"
                        />
              
                <CardActions disableSpacing>
                  <ExpandMore
                      expand={item.expanded}
                      onClick={item.handleExpandClick}
                      aria-expanded={item.expanded}
                      aria-label="show more"
                      className="text-center"                    
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={item.expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                  <h4>{item.Info}</h4>
                  </CardContent>
                </Collapse>
              </Card>
            </Col>
          ))}

        </Row>
          <Row style={{paddingBottom:"10px"}}><b>In addition to our founders, we have over 50 Volunteers in total. They are made up of both the younger and older generation.</b></Row>
          <Row>We aim to empower the youth in everything we do. In involving them throughout the planning stages, organisation and management of events alongside the adults, we teach them the lifeskills that will support them later on in their bright futures.</Row>
        </Container>
        </div>
  </>
  );
}

export default Volunteers;