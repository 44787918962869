import * as React from 'react';
import Row from 'react-bootstrap/Row';
import "../../App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Col from 'react-bootstrap/Col';
import diversityImage from '../../Images/diversity.png';
import arabImage from '../../Images/arab.png';
import legoinclusion from '../../Images/legointegration.png';

const WhoWeAre = () => {

  return (
      <>
        <Row>
        <Col style={{paddingBottom:"20px"}}>
          <img style={{ borderRadius:"50px"}} src={legoinclusion} alt="Lamet Habayeb Association Supporting The Community"/>
          <h2>Supporting the Community</h2>
          <p style={{paddingRight:"40px", paddingLeft:"40px"}}><b>We at Lamet Habayeb are here when you need us!</b></p>
          <p style={{paddingRight:"40px", paddingLeft:"40px"}}>We provide a variety of services that aim at helping and supporting all vulnerable members in our society. Where we cannot provide a service, we always ensure that we work with fellow organisations, community groups and charities to ensure that everyone gets the right support.</p>
          <p><a className="btn btn-default" href="/our-aims/supporting-the-community" role="button"><b>View details &raquo;</b></a></p>
        </Col>
        <Col style={{paddingBottom:"20px"}}>
          <img style={{ borderRadius:"50px"}} src={arabImage} alt="Lamet Habayeb Association Welcome stage message"/>
          <h2>Integrating the Arab Community</h2>
          <p style={{paddingRight:"40px", paddingLeft:"40px"}}><b>The Arab community is a minority in the UK.</b></p>
          <p style={{paddingRight:"40px", paddingLeft:"40px"}}>Through the amazing support of funders, partner charities and (<b>most importantly</b>) our volunteers, we at Lamet Habayeb, aim to integrate the Arab community into British society by sharing our culture, heritage and history with others of different faiths and backgrounds. In doing so, we cultivate a sense of trust, respect and most importantly safety which allows our members to contribute further to society. </p>
          <p><a className="btn btn-default" href="/our-aims/integrating-the-arab-community" role="button"><b>View details &raquo;</b></a></p>
        </Col>
        <Col  style={{paddingBottom:"20px"}}>
          <img style={{ borderRadius:"50px"}} src={diversityImage} alt="Lamet Habayeb Association diversity"/>
          <h2>Everyone is Welcome!</h2>
          <p style={{ paddingRight: "40px", paddingLeft: "40px" }}><b>The Arab world is not just the Middle East.</b></p>
          <p style={{paddingRight:"40px", paddingLeft:"40px"}}>From across the Arabian peninsula to Africa, Arabs are some of the most diverse groups of people on Earth. This means that we at Lamet Habayeb show respect to everyone, whatever their colour, culture or creed. It also means that we welcome all people with open arms!</p>
          <p><a className="btn btn-default" href="/our-aims/everyone-is-welcome" role="button"><b>View details &raquo;</b></a></p>
        </Col>
        </Row>
      </>  
    )
}

export default WhoWeAre;