import * as React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from '../../Components/NavigationBar';


const PayPalSuccess = () => {
  return (
    <>
      <NavigationBar/>
      <div>
      <Container className="text-center" style ={{padding:"20px"}}>   
        <div>
                      <Row style={{paddingBottom:"10px"}}><b>A HUGE THANK YOU for your donation.</b></Row>
                      <Row style={{justifyContent:"center", paddingBottom:"10px"}}>Your support makes everything we do a reality and a success!</Row>
                      <Row style={{paddingBottom:"10px"}}><b>If you would like to continue to hear about the great work we do, drop us an email with your <b>full name and affiliation</b> on <a href='mailto:lamethabayeb@yahoo.com'>lamethabayeb@yahoo.com</a> and we will add you to our contact list. You will also be among the first people invited to our events!</b></Row>
        </div>
        </Container>
        </div>
  </>
  );
}

export default PayPalSuccess;