import * as React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from '../../Components/NavigationBar';
import SupportingOurCommunity from "../../Images/HomePageArticleImages/SupportingOurCommunity.png"
import Carousel from "react-bootstrap/Carousel";
import Aos from 'aos';


const SupportingTheCommunity = () => {
    React.useEffect(() => {
        Aos.init({ duration: 100 });
    }, []);
  return (
      <>
      <NavigationBar />
        <h1 className="ourvalues" style={{marginTop:"10px", marginBottom:"30px"}}>Supporting the Community</h1>
      <div style={{backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)", padding:"20px"}}>
        <Container>
              <Row>
                  <Col md={12} sm={12} xs={12} lg={6} xl={6} data-aos="fade-up"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="800"
                      data-aos-offset="0">
                      <Carousel variant="dark" className="d-block w-100" >
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={SupportingOurCommunity}
                                  alt="Second slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={SupportingOurCommunity}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={SupportingOurCommunity}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={SupportingOurCommunity}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src={SupportingOurCommunity}
                                  alt="Third slide"
                              />
                          </Carousel.Item>
                      </Carousel>
                  </Col>
                  <Col md={12} sm={12} xs={12} lg={6} xl={6} data-aos="fade-up"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="400"
                      data-aos-offset="0">
              <Row className='text-center' style={{ paddingTop: "2%", paddingBottom: "20px" }}><b>We at Lamet Habayeb are here when you need us!</b></Row>
                  <Row style={{paddingBottom:"10px"}}>We provide a variety of services that aim at helping and supporting all vulnerable members in our society. Where we cannot provide a service, we always ensure that we work with fellow organisations, community groups and charities to ensure that everyone gets the right support.</Row>
                  <Row style={{paddingBottom:"10px"}}> From supporting people throughout the different waves of the COVID-19 pandemic by providing food parcels to celebrating the Arab communities culture and diversity with everyone through our many events and celebrations, we are a community-centric organisation with a focus on developing the image of the Arab community in British society whilst also giving back to our communities.</Row>
                  <Row style={{paddingBottom:"10px"}}>Check out some of the activities, events and projects were currently organising and have been involved in:</Row>
                  <ul>
                    <li>
                      <a href='/our-work/youth-club'>Youth Club</a>
                    </li>
                    <li>
                      <a href="/our-work/cooking-lessons">Cooking Lessons</a>
                    </li>
                    <li>
                      <a href="/our-work/wellbeing-projects">Wellbeing Projects</a>
                    </li>
                    <li>
                      <a href="/our-work/coffee-mornings">Coffee Mornings</a>
                    </li>
                    <li>
                      <a href="/our-work/lha-football-team">Lamet Habayeb Association Football Team - formally known as the <b>LHA Lions</b></a>
                    </li>
                    <li>
                      <a href="/our-work/trips">Scenic and Cultural Trips</a>
                    </li>
                    <li>
                      <a href="/our-work/walking-group">Walking Group</a>
                    </li>
                    <li>
                      <a href="/our-work/celebrations">Celebrations</a>
                    </li>
                  </ul>
                  <Row><b>You can either click on the links above to learn more or navigate to other sections of the website through the navigation bar</b></Row>
          </Col>
              </Row>
          </Container>
        </div>
        </>
  );
}

export default SupportingTheCommunity;