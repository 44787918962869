import * as React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationBar from '../Components/NavigationBar';


const PageInMaking = () => {
  return (
    <>
      <NavigationBar/>
      <div>
      <Container className="text-center" style ={{padding:"20px"}}>   
        <div>
                      <Row><b>This page is in the making</b></Row>
                      <Row style={{justifyContent:"center"}}>Sorry about this, this page is being developed. Do check back in in a few days time.</Row>
                      <Row><b>You can navigate to other sections of the website and check out the other great work we do through the navigation bar.</b></Row>
        </div>
        </Container>
        </div>
  </>
  );
}

export default PageInMaking;