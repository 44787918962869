import React from 'react';
import BasicModal from '../Components/Modal';
import Row from 'react-bootstrap/Row';
import Aos from "aos";
import "aos/dist/aos.css";
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Toolbar from '@mui/material/Toolbar';
import Box from '@material-ui/core/Box';
import NavigationBar from '../Components/NavigationBar';
import Volunteers from './FoundersAndVolunteers/Volunteers';
import ToBeProvided from "../Images/CheckBackSoon.png";


// Trial
function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

const VolunteersPage = (props) => {
  React.useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <>
      <NavigationBar />
        <Toolbar>
        <h1 data-aos="fade-up"  style={{ color: "white", marginTop:"20px"}} className='ourvalues' >Volunteers</h1>
        </Toolbar>
        <Toolbar id="back-to-top-anchor"/>
        <div style={{backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)"}}>
          {/* {volunteerDetails.map((item) => (
            <div data-aos="fade-right" className='text-center col-6-sm col-md-6 col-lg-6 col-xl-6 col-xs-6'>
              <h1 style={{ color: "gold" }} className='volunteers'>{item.title}</h1>
              <br />
              <div>
                <img src={item.img} alt="Omar Sharif - Vice Chair" />
              </div>
              <br />
              <h4 style={{ color: "gold" }} className='volunteers'>{item.subtitle}</h4>
              <br />
            </div>

          ))}         */}

          <Volunteers/>
        </div>
        <Row data-aos="fade-up">
              <BasicModal images={itemData} />
        </Row>
        <ScrollTop {...props}>
          <Fab style={{background:"gold"}} color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
        </>
    )
}

const itemData = [
  {
    img: `${ToBeProvided}`,
    title: 'Upcoming photo',
    link: `${ToBeProvided}`,
    rows: 2,
    cols: 2,
  },
  {
    img: `${ToBeProvided}`,
    title: 'Upcoming Photo',
    link: `${ToBeProvided}`,
  },
  {
    img: `${ToBeProvided}`,
    title: 'Upcoming photo',
  },
  {
    img: `${ToBeProvided}`,
    title: 'Upcoming photo',
    Link: `${ToBeProvided}`,
    cols: 2,
  },
  {
    img: `${ToBeProvided}`,
    title: 'Upcoming Photo',
    Link: `${ToBeProvided}`,
    cols: 2,
  },
  {
    img: `${ToBeProvided}`,
    Link: `${ToBeProvided}`,
    title: 'Upcoming Photo',
    author: '@LametHabayeb',
    rows: 2,
    cols: 2,
  },
  {
    img: `${ToBeProvided}`,
    title: 'Upcoming Photo',
    Link: `${ToBeProvided}`,
  },
  {
    img: `${ToBeProvided}`,
    title: 'Upcoming Photo',
    Link: `${ToBeProvided}`,
  },
  {
    img: `${ToBeProvided}`,
    title: 'Upcoming Photo',
    Link: `${ToBeProvided}`,
    rows: 2,
    cols: 2,
  },
  {
    img: `${ToBeProvided}`,
    title: 'Upcoming Photo',
    Link: `${ToBeProvided}`,
  },
  {
    img: `${ToBeProvided}`,
    title: 'Upcoming Photo',
    Link: `${ToBeProvided}`,
  },
  {
    img: `${ToBeProvided}`,
    title: 'Upcoming Photo',
    Link: `${ToBeProvided}`,
    cols: 2,
  },
];

// const volunteerDetails = [
//   {
//     title: "Founder and Chair",
//     img: `${Chair}`,
//     subtitle:"Wafa Takheroubt"
//   },
//   {
//     title: "Founder",
//     img: `${Founder}`,
//     subtitle:"Dr Souhad Sharif"
//   },
//   {
//     title: "Founder",
//     img: `${Founder}`,
//     subtitle:"Dr Souhad Sharif"
//   },
//   {
//     title: "Founder",
//     img: `${Founder}`,
//     subtitle:"Dr Souhad Sharif"
//   },
// ];
// // title,img,subtitle,

export default VolunteersPage;