import React from "react";
import Logo from "./Logo";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from 'react-bootstrap/NavDropdown'
import Col from "react-bootstrap/Col";
import { Link } from 'react-router-dom';

const NavigationBar = () => {
	return (
		// TAKE A LOOK AT THIS https://react-bootstrap.github.io/components/dropdowns/
		<>
			<Navbar collapseOnSelect expand="lg" variant="light" style={{backgroundColor:"white", marginTop:"-20px"}} sticky="top">
				<Container className="text-center" >
					<Col >
						<Navbar.Brand href="/home">{<Logo />}</Navbar.Brand>
					</Col>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
					<Nav variant="tabs" className="me-auto">
					<Nav.Link href="/home">Home</Nav.Link>
					<Nav.Link href="/what-we-do">What we do</Nav.Link>
					<Nav.Link href="/Volunteers">Volunteers</Nav.Link>
					<NavDropdown title="Events and Projects" id="collasible-nav-dropdown" >
						<NavDropdown.Item href="/our-work/youth-club">Youth Club</NavDropdown.Item>
						<NavDropdown.Item href="/our-work/cooking-lessons">Cooking Lessons</NavDropdown.Item>
						<NavDropdown.Item href="/our-work/wellbeing-projects">Wellbeing Projects</NavDropdown.Item>
						<NavDropdown.Item href="/our-work/coffee-mornings">Coffee Mornings</NavDropdown.Item>
						<NavDropdown.Item href="/our-work/lha-football-team">Lamet Habayeb Football Team</NavDropdown.Item>
						<NavDropdown.Item href="/our-work/trips">Scenic and Cultural Trips</NavDropdown.Item>
						<NavDropdown.Item href="/our-work/walking-group">Walking Group</NavDropdown.Item>
						<NavDropdown.Item href="/our-work/celebrations">Celebrations</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item href="https://www.paypal.com/donate/?hosted_button_id=CPZGJGCJZJBSA">Donate</NavDropdown.Item>
					</NavDropdown>
					</Nav>
					<Nav>
					<Nav.Link href="/contact-us" style={{color:"black"}}>Contact Us</Nav.Link>
					<Nav.Link eventKey={2} style={{color:"black"}} href="/Donations">
						Donate
					</Nav.Link>
					{/* <Nav.Link as={Link} to="/login" style={{color:"black"}}>Volunteer Login</Nav.Link> */}
					</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
};

export default NavigationBar;

